<!-- Breadcrumb Begin -->
<div class="breadcrumb-option">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="breadcrumb__links">
                    <a href="../Home"><i class="fa fa-home"></i> Home</a>
                    <span>Shopping</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Breadcrumb End -->
<!-- Shop Section Begin -->
<section class="shop spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <div class="shop__sidebar">
                    <div class="sidebar__categories">
                        <div class="section-title">
                            <h4>Categories</h4>
                        </div>
                        <div class="categories__accordion">
                            <div class="accordion" id="accordionExample">
                                <div class="card">
                                    <a style="cursor: pointer;" (click)="AllDATA()">All</a>
                                </div>
                                <div class="card" *ngFor="let categorie of Categoriesdata()">
                                    <a style="cursor: pointer;" (click)="FilterData(categorie)">{{categorie}}</a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="sidebar__filter">
                        <div class="section-title">
                            <h4>Shop by price</h4>
                        </div>
                        <div class="filter-range-wrap">
                            <ngx-slider [(value)]="value" [options]="options"></ngx-slider>
                        </div>
                        <div class="mt-3">
                            <button type="button" (click)="FilterPrice()" class="site-btn">Filter</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 col-md-9">
                <div class="row">
                    <div id="product__item" class="col-lg-4 col-md-6" *ngFor="let Coin of Data">
                        <div class="product__item">
                            <div class="product__item__pic" style="background-image: url('https://api.ultracamd.info{{Coin.picture}}'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
                                <div class="label2 new">Coin {{Coin.price}}</div>
                                <ul class="product__hover">
                                    <li><a style="cursor: pointer;" (click)="addFavorite(Coin.id)"><span class="icon_heart_alt"></span></a></li>
                                    <li><a style="cursor: pointer;" (click)="Buy(Coin.id)"><span class="icon_bag_alt"></span></a></li>
                                </ul>
                            </div>
                            <div class="product__item__text">
                                <h6><a href="#">{{Coin.name}}</a></h6>
                                <div class="product__price" *ngIf="money =='da'">Da {{Coin.prix_detail}}</div>
                                <div class="product__price" *ngIf="money =='euro'">€ {{Coin.prix_euro_detail}}</div>
                            </div>
                        </div>
                    </div>

                    <!--<div class="col-lg-12 text-center">
            <div class="pagination__option">
              <a href="#">1</a>
              <a href="#">2</a>
              <a href="#">3</a>
              <a href="#"><i class="fa fa-angle-right"></i></a>
            </div>
          </div>-->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Shop Section End -->
<!-- Instagram Begin -->
<hr>
