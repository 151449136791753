<!-- Categories Section Begin -->
<section class="categories">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 p-0">
        <div class="categories__item categories__large__item"
          style="background-image: url('assets/img/categories/category-1.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
          <!--<div class="categories__text">
            <h1>Charge Cards</h1>
            <p>You can Buy Solds, or Get your Own Credit Card.</p>
            <a href="../Charge_Cards">Buy now</a>
          </div>-->
        </div>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 p-0">
            <div class="categories__item"
              style="background-image: url('assets/img/categories/category-2.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
              <!--<div class="categories__text">
                <h4>Itunes Cards</h4>
                <p>All Cards here</p>
                <a href="../Gift_Cards">Buy now</a>
              </div>-->
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 p-0">
            <div class="categories__item"
              style="background-image: url('assets/img/categories/category-3.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
              <!--<div class="categories__text">
                <h4>Amazon Cards</h4>
                <p>All Cards here</p>
                <a href="../Gift_Cards">Buy now</a>
              </div>-->
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 p-0">
            <div class="categories__item"
              style="background-image: url('assets/img/categories/category-4.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
              <!--<div class="categories__text">
                <h4>Xbox Cards</h4>
                <p>All Cards here</p>
                <a href="../Gift_Cards">Buy now</a>
              </div>-->
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 p-0">
            <div class="categories__item"
              style="background-image: url('assets/img/categories/category-5.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
              <!--<div class="categories__text">
                <h4>Steam Cards</h4>
                <p>All Cards here</p>
                <a href="../Gaming_Cards">Buy now</a>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Categories Section End -->
<!-- Product Section Begin -->
<section class="product spad">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-4">
        <div class="section-title">
          <h4>Last New Products</h4>
        </div>
      </div>
      <!--<div class="col-lg-8 col-md-8">
        <ul class="filter__controls">
          <li class="active" data-filter="*">All</li>
          <li data-filter=".Google">Google</li>
          <li data-filter=".Itunes">Itunes</li>
          <li data-filter=".Amazon">Amazon</li>
          <li data-filter=".Steam">Steam</li>
          <li data-filter=".Xbox">Xbox</li>
        </ul>
      </div>-->
    </div>
    <div class="row property__gallery">
      <div class="col-lg-3 col-md-4 col-sm-6 mix Google" *ngFor="let Card of Cards">
        <div class="product__item">
          <div class="product__item__pic"
            style="background-image: url('https://eleulmasat.com.dz/Activation{{Card.path_picture}}'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
            <!--<div class="label2 new">$ {{Card.price}}</div>
            <div class="label sale" *ngIf="Card.dispo == null">out of stock</div>
            <div class="label stockblue" *ngIf="Card.dispo !== null">ON STOCK: {{Card.dispo}}</div>-->
            <ul class="product__hover">
              <li><a style="cursor: pointer;" (click)="addFavorite(Card.id)"><span class="icon_heart_alt"></span></a>
              </li>
              <li><a style="cursor: pointer;" (click)="Buy(Card.id)"><span class="icon_bag_alt"></span></a></li>
            </ul>
          </div>
          <div class="product__item__text">
            <h6><a href="#">{{Card.Name}}</a></h6>
            <!--<div class="rating">
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
            </div>-->
            <div class="product__price" *ngIf="money =='da'">Da {{Card.prix_detail}}</div>
            <div class="product__price" *ngIf="money =='euro'">€ {{Card.prix_euro_detail}}</div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product Section Begin -->
<!-- Product Section End -->
<!-- Banner Section Begin -->
<!--<section class="banner"
  style="background-image: url('assets/img/banner/banner-1.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
  <div class="container">
    <div class="row">
      <div class="col-xl-7 col-lg-8 m-auto">
        <div class="banner__slider owl-carousel">
          <div class="banner__item">
            <div class="banner__text">
              <span>Gamers World</span>
              <h1>Fortnite Cards</h1>
              <a href="./Gaming_Cards">Buy now</a>
            </div>
          </div>
          <div class="banner__item">
            <div class="banner__text">
              <span>Gamers World</span>
              <h1>Pubg Cards</h1>
              <a href="./Gaming_Cards">Buy now</a>
            </div>
          </div>
          <div class="banner__item">
            <div class="banner__text">
              <span>Gamers World</span>
              <h1>Wow Cards</h1>
              <a href="./Gaming_Cards">Buy now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>-->
<!-- Banner Section End -->
<!-- Trend Section Begin -->

<!-- Trend Section End -->
<!-- Discount Section Begin -->
<!--<section class="discount">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 p-0">
        <div class="discount__pic">
          <img src="assets/img/xdiscount.jpg.pagespeed.ic._v9FuG3AaK.jpg" alt="">
        </div>
      </div>
      <div class="col-lg-6 p-0">
        <div class="discount__text ">
          <div class="discount__text__title mt-3 pb-3">
            <span>Discount</span>
            <h2>Summer 2020</h2>
            <h5><span>Sale</span> 50%</h5>
          </div>
          <a href="#" class="mt-3">Buy now</a>
        </div>
      </div>
    </div>
  </div>
</section>-->
<!-- Discount Section End -->
<!-- Services Section Begin -->
<hr>
<section class="services spad">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="services__item">
          <i class="fa fa-car"></i>
          <h6>Free Shipping</h6>
          <p>Free Shipping and fast</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="services__item">
          <i class="fa fa-money"></i>
          <h6>Money Back Guarantee</h6>
          <p>If good have Problems</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="services__item">
          <i class="fa fa-support"></i>
          <h6>Online Support 24/7</h6>
          <p>Dedicated support</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="services__item">
          <i class="fa fa-headphones"></i>
          <h6>Payment Secure</h6>
          <p>100% secure payment</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Services Section End -->
<!-- Instagram Begin -->
<hr>

<section class="trend spad">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-6">
        <div class="trend__content">
          <div class="section-title">
            <h4>Hot Trend</h4>
          </div>
          <div class="trend__item">
            <div class="trend__item__pic">
              <img src="assets/img/trend/1.jpg" alt="">
            </div>
            <div class="trend__item__text">
              <h6>Google Card</h6>
              <div class="rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
              </div>
              <div class="product__price">$ 25.0</div>
            </div>
          </div>
          <div class="trend__item">
            <div class="trend__item__pic">
              <img src="assets/img/trend/1.jpg" alt="">
            </div>
            <div class="trend__item__text">
              <h6>Google Card</h6>
              <div class="rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
              </div>
              <div class="product__price">$ 25.0</div>
            </div>
          </div>
          <div class="trend__item">
            <div class="trend__item__pic">
              <img src="assets/img/trend/1.jpg" alt="">
            </div>
            <div class="trend__item__text">
              <h6>Google Card</h6>
              <div class="rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
              </div>
              <div class="product__price">$ 25.0</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6">
        <div class="trend__content">
          <div class="section-title">
            <h4>Best seller</h4>
          </div>
          <div class="trend__item">
            <div class="trend__item__pic">
              <img src="assets/img/trend/1.jpg" alt="">
            </div>
            <div class="trend__item__text">
              <h6>Google Card</h6>
              <div class="rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
              </div>
              <div class="product__price">$ 25.0</div>
            </div>
          </div>
          <div class="trend__item">
            <div class="trend__item__pic">
              <img src="assets/img/trend/1.jpg" alt="">
            </div>
            <div class="trend__item__text">
              <h6>Google Card</h6>
              <div class="rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
              </div>
              <div class="product__price">$ 25.0</div>
            </div>
          </div>
          <div class="trend__item">
            <div class="trend__item__pic">
              <img src="assets/img/trend/1.jpg" alt="">
            </div>
            <div class="trend__item__text">
              <h6>Google Card</h6>
              <div class="rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
              </div>
              <div class="product__price">$ 25.0</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6">
        <div class="trend__content">
          <div class="section-title">
            <h4>Feature</h4>
          </div>
          <div class="trend__item">
            <div class="trend__item__pic">
              <img src="assets/img/trend/1.jpg" alt="">
            </div>
            <div class="trend__item__text">
              <h6>Google Card</h6>
              <div class="rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
              </div>
              <div class="product__price">$ 25.0</div>
            </div>
          </div>
          <div class="trend__item">
            <div class="trend__item__pic">
              <img src="assets/img/trend/1.jpg" alt="">
            </div>
            <div class="trend__item__text">
              <h6>Google Card</h6>
              <div class="rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
              </div>
              <div class="product__price">$ 25.0</div>
            </div>
          </div>
          <div class="trend__item">
            <div class="trend__item__pic">
              <img src="assets/img/trend/1.jpg" alt="">
            </div>
            <div class="trend__item__text">
              <h6>Google Card</h6>
              <div class="rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
              </div>
              <div class="product__price">$ 25.0</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<hr>
