import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class CONTACTComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('#home').removeClass('active');
    $('#Gift_Cards').removeClass('active');
    $('#Gaming_Cards').removeClass('active');
    $('#Charge_Cards').removeClass('active');
    $('#Contact').addClass('active');
  }

}
