import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  Card: {};
  money: any;
  newVal: any;
  oldValue: any;
  constructor(private router: Router) { }

  // tslint:disable-next-line:typedef
  Qty() {
    /*-------------------
    Quantity change
    --------------------- */
    const proQty = $('.pro-qty');

    proQty.on('click', '.qtybtn', () => {
      const $button = $(this);
      console.log($button);

      this.oldValue = proQty.parent().find('input').val();

      console.log(this.oldValue)
      console.log($button.hasClass('dec'))
      if ($button.hasClass('dec')) {
        // tslint:disable-next-line:no-shadowed-variable
        this.newVal = parseFloat(this.oldValue) + 1;
      } else {
        // Don't allow decrementing below zero
        if (this.oldValue > 0) {
          this.newVal = parseFloat(this.oldValue) - 1;
        } else {
          this.newVal = 0;
        }
      }
      $button.parent().find('input').val(this.newVal);
    });

  }

  ngOnInit(): void {
    this.Card = JSON.parse(sessionStorage.getItem('Buy'));
    this.money = sessionStorage.getItem('money');



  }

}
