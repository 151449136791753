import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from './http.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'El EULMA SAT';
  Favorite: [];
  Profile: [];
  money: any;

  SubscribeForm: FormGroup;
  errorMessage: string;
  Registration: { email: any; }[];

  // tslint:disable-next-line:max-line-length
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private httpService: HttpService) {
    const config = {
      apiKey: 'AIzaSyAdAZ8f1izcjzbeFDVRHVjPwwT-vgtWrYI',
      authDomain: 'eleulma-web.firebaseapp.com',
      projectId: 'eleulma-web',
      storageBucket: 'eleulma-web.appspot.com',
      messagingSenderId: '95686797171',
      appId: '1:95686797171:web:72e7db303d726ac0650706',
      measurementId: 'G-VDE8PZNGQ8'
    };
    firebase.initializeApp(config);
  }

  // tslint:disable-next-line:typedef
  GetMoney() {
    this.money = sessionStorage.getItem('money');

    if (this.money === 'da') {
      sessionStorage.setItem('money', 'euro');
      this.money = 'euro';
      location.reload();

    } else {
      sessionStorage.setItem('money', 'da');
      this.money = 'da';
      location.reload();

    }
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): void {
    this.initForm();

    this.Favorite = JSON.parse(sessionStorage.getItem('wishlist'));
    this.Profile = JSON.parse(sessionStorage.getItem('Profile'));
    this.money = sessionStorage.getItem('money');

    if (this.money == null) {
      sessionStorage.setItem('money', 'da');
      this.money = 'da';
      $('#money').val('da');

    } else {
      this.money = sessionStorage.getItem('money');
      $('#money').val(this.money);

    }

    if (this.Profile == null) {
      sessionStorage.setItem('Profile', '[]');
      this.Profile = [];
    } else {
      this.Profile = JSON.parse(sessionStorage.getItem('Profile'));
    }
    if (this.Favorite == null) {
      sessionStorage.setItem('wishlist', '[]');
      this.Favorite = [];

    } else {
      this.Favorite = JSON.parse(sessionStorage.getItem('wishlist'));
    }

  }

  // tslint:disable-next-line:typedef
  initForm() {
    this.SubscribeForm = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.email]]
    });
  }

  // tslint:disable-next-line:typedef
  onSubmit() {
    const email = this.SubscribeForm.get('Email').value;

    this.httpService.subscribe(email).subscribe(data => {
      if ((data as any).Status === 'Success') {
        Swal.fire(
          'Good job!',
          'Successfully Subscribed!',
          'success'
        );
      } else {
        Swal.fire(
          'Warning!',
          'Email Exist on our Database!',
          'warning'
        );
      }
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {

  }

  // tslint:disable-next-line:typedef
  logout() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout Now!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.Favorite = [];
        this.Profile = [];
        this.authService.signOutUser();
        Swal.fire(
          'Logout!',
          'Successfully Logout.',
          'success'
        );
      }
    });
  }

  // tslint:disable-next-line:typedef
  SetData() {
    this.Favorite = JSON.parse(sessionStorage.getItem('wishlist'));
    this.Profile = JSON.parse(sessionStorage.getItem('Profile'));
  }
}
