import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('#home').removeClass('active');
    $('#Gift_Cards').removeClass('active');
    $('#Gaming_Cards').removeClass('active');
    $('#Charge_Cards').removeClass('active');
    $('#Contact').removeClass('active');
  }

}
