import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import * as firebase from 'firebase/app';
import 'firebase/auth';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

  constructor(private router: Router) { }

  // tslint:disable-next-line:no-unused-expression
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().onAuthStateChanged(
          (user) => {
            if (user) {
              resolve(true);
            } else {
              sessionStorage.setItem('Profile', '[]');
              sessionStorage.setItem('wishlist', '[]');
              sessionStorage.setItem('access', '');
              reject(true);
              this.router.navigate(['./Login']);
              // location.reload();
            }
          }
        );
      }
    );
  }
}
