<!-- Breadcrumb Begin -->
<div class="breadcrumb-option">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="breadcrumb__links">
          <a href="./Home"><i class="fa fa-home"></i> Home</a>
          <a href="#">Checkout </a>
          <span *ngFor="let data of Card">{{data.name}} {{data.price}} $</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Breadcrumb End -->
<!-- Product Details Section Begin -->
<section class="product-details spad">
  <div class="container">
    <div class="row">
      <div class="col-lg-6" *ngFor="let data of Card">
        <div class="product__details__pic">
          <div class="product__details__pic__left product__thumb nice-scroll">
            <a class="pt" href="./Home">
              <img src="https://api.ultracamd.info{{data.picture}}" alt="">
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="product__details__text" *ngFor="let data of Card">
          <h3>{{data.name}}<span>Card Value: {{data.price}} $</span></h3>
          <div class="product__details__price" *ngIf="money =='da'">Da {{data.prix_detail}}</div>
          <div class="product__details__price" *ngIf="money =='euro'">€ {{data.prix_euro_detail}}</div>

          <div class="product__details__button">
            <div class="quantity">
              <span>Quantity:</span>
              <div class="pro-qty">
                <span class="dec qtybtn" (click)="Qty()">-</span>
                <input type="text" value="1">
                <span class="inc qtybtn" (click)="Qty()">+</span>
              </div>
            </div>
            <a href="#" class="cart-btn"><span class="icon_bag_alt"></span> Checkout</a>
            <ul>
              <li><a href="#"><span class="icon_heart_alt"></span></a></li>
            </ul>
          </div>
          <div class="product__details__widget">
            <ul>
              <li>
                <span>Availability:</span>
                <div class="stock__checkbox" *ngIf="data.dispo ==null">
                  <label for="stockin">
                    In Stock
                    <input type="checkbox" id="stockin" disabled>
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="stock__checkbox" *ngIf="data.dispo !==null">
                  <label for="stockin">
                    In Stock
                    <input type="checkbox" id="stockin" checked disabled>
                    <span class="checkmark"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="product__details__tab">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Payment</a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane active" id="tabs-1" role="tabpanel">
              <h6>Description</h6>
              <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut loret fugit, sed
                quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt loret.
                Neque porro lorem quisquam est, qui dolorem ipsum quia dolor si. Nemo enim ipsam
                voluptatem quia voluptas sit aspernatur aut odit aut loret fugit, sed quia ipsu
                consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Nulla
                consequat massa quis enim.</p>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
                dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product Details Section End -->
<!-- Instagram Begin -->
<div class="instagram">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-2 col-md-4 col-sm-4 p-0">
        <div class="instagram__item"
          style="background-image: url('assets/img/instagram/insta-1.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
          <div class="instagram__text">
            <i class="fa fa-instagram"></i>
            <a href="#">@E-Store</a>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4 p-0">
        <div class="instagram__item"
          style="background-image: url('assets/img/instagram/insta-2.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
          <div class="instagram__text">
            <i class="fa fa-instagram"></i>
            <a href="#">@E-Store</a>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4 p-0">
        <div class="instagram__item"
          style="background-image: url('assets/img/instagram/insta-3.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
          <div class="instagram__text">
            <i class="fa fa-instagram"></i>
            <a href="#">@E-Store</a>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4 p-0">
        <div class="instagram__item"
          style="background-image: url('assets/img/instagram/insta-4.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
          <div class="instagram__text">
            <i class="fa fa-instagram"></i>
            <a href="#">@E-Store</a>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4 p-0">
        <div class="instagram__item"
          style="background-image: url('assets/img/instagram/insta-5.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
          <div class="instagram__text">
            <i class="fa fa-instagram"></i>
            <a href="#">@E-Store</a>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4 p-0">
        <div class="instagram__item "
          style="background-image: url('assets/img/instagram/insta-6.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
          <div class="instagram__text">
            <i class="fa fa-instagram"></i>
            <a href="#">@E-Store</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
