import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { Cards } from '../cards';
import * as $ from 'jquery';
import { Options } from '@angular-slider/ngx-slider';
import Swal from 'sweetalert2';
import { AppComponent } from './../app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.css']
})

export class GiftCardComponent implements OnInit {
  Cards: Cards[];
  Data: any;
  categories: any;
  Card: any;
  money: any;
  value: any;
  options: Options;





  constructor(private httpService: HttpService, private app: AppComponent, private router: Router) {
    this.httpService.GetCards().subscribe(data => {
      this.Cards = data;
      this.Data = data;
    });
  }

  // tslint:disable-next-line:typedef
  Categoriesdata() {
    this.categories = [];
    for (const result of this.Cards) {
      this.categories.push(result.name);
    }
    this.categories = this.categories.filter((elem, i, arr) => {
      if (arr.indexOf(elem) === i) {
        return elem;
      }
    });
    return this.categories;
  }

  // tslint:disable-next-line:typedef
  FilterData(test) {
    $('#product__item .product__item').fadeOut(1000, () => {
      this.Data = this.Cards.filter((hero) => {
        return hero.name === test;
      });
      $('#product__item .product__item').fadeIn(500);
    });
  }


    // tslint:disable-next-line:typedef
    Buy(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, i Confirm it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.Card = this.Cards.filter((hero) => {
            return hero.id === id;
          });
          sessionStorage.setItem('Buy', JSON.stringify(this.Card));
          this.router.navigate(['./Checkout']);
        }
      });
  
  
    }

  // tslint:disable-next-line:typedef
  addFavorite(id) {
    const Profile = JSON.parse(sessionStorage.getItem('Profile'));
    const Favorite = JSON.parse(sessionStorage.getItem('wishlist'));

    const check = Favorite.some((el) => {
      return el.id === id;
    });

    if (Profile.length > 0) {
      this.Card = this.Cards.filter((hero) => {
        return hero.id === id;
      });
      if (check === false) {

        // tslint:disable-next-line:variable-name
        const Fav = [{
          // tslint:disable-next-line:object-literal-shorthand
          id: id
        }];

        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Favorite it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.httpService.Favorite(Fav).subscribe(data => {
              if ((data as any).Status === 'Success') {
                Favorite.push(this.Card[0]);
                sessionStorage.setItem('wishlist', JSON.stringify(Favorite));
                this.app.SetData();
              } else {
                Swal.fire(
                  'Warning!',
                  'Error to Favorite Card!',
                  'warning'
                );
              }
            });
            Swal.fire(
              'Favorite!',
              'This Card has been add in Favorite List.',
              'success'
            );
          }
        });
      } else {
        Swal.fire(
          'Warning!',
          'Exist In Favorite List!',
          'warning'
        );
      }

    } else {
      this.router.navigate(['./Login']);
    }


  }


  // tslint:disable-next-line:typedef
  FilterPrice() {
    if (this.money === 'euro') {

      this.Data = this.Cards.filter((hero) => {
        return hero.prix_euro_detail >= 0 && hero.prix_euro_detail <= this.value;
      });
    } else {
      this.Data = this.Cards.filter((hero) => {
        return hero.prix_detail >= 0 && hero.prix_detail <= this.value;
      });
    }
  }

  // tslint:disable-next-line:typedef
  AllDATA() {
    $('#product__item .product__item').fadeOut(1000, () => {
      this.Data = this.Cards;
      $('#product__item .product__item').fadeIn(500);
    });
  }

  ngOnInit(): void {
    this.money = sessionStorage.getItem('money');
    if (this.money === 'euro') {
      this.value = 500;
      this.options = {
        floor: 0,
        ceil: 500,
        getPointerColor: (value: number): string => {
          return '#dc3545';
        }
      };
    } else {
      this.value = 50000;
      this.options = {
        floor: 0,
        ceil: 50000,
        getPointerColor: (value: number): string => {
          return '#dc3545';
        }
      };
    }
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {

    $('#home').removeClass('active');
    $('#Gift_Cards').addClass('active');
    $('#Gaming_Cards').removeClass('active');
    $('#Charge_Cards').removeClass('active');
    $('#Contact').removeClass('active');

  }
}
