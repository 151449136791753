import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { HttpService } from '../http.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})

export class SignupComponent implements OnInit {

  signupForm: FormGroup;
  errorMessage: string;
  Registration: { email: any; Address: any; City: any; Zip: any; Country: any; Phone: any; FullName: string; }[];

  // tslint:disable-next-line:max-line-length
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private httpService: HttpService) { }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.initForm();
    $('#home').removeClass('active');
    $('#Gift_Cards').removeClass('active');
    $('#Gaming_Cards').removeClass('active');
    $('#Charge_Cards').removeClass('active');
    $('#Contact').removeClass('active');
  }

  // tslint:disable-next-line:typedef
  initForm() {
    this.signupForm = this.formBuilder.group({
      FirstName: ['', [Validators.required, Validators.minLength(5)]],
      LastName: ['', [Validators.required, Validators.minLength(5)]],
      Address: ['', [Validators.required, Validators.minLength(5)]],
      City: ['', [Validators.required, Validators.minLength(5)]],
      Zip: ['', [Validators.required, Validators.minLength(5)]],
      Country: ['', [Validators.required, Validators.minLength(5)]],
      Phone: ['', [Validators.required, Validators.minLength(5)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]]
    });
  }

  // tslint:disable-next-line:typedef
  onSubmit() {
    const email = this.signupForm.get('email').value;
    const password = this.signupForm.get('password').value;
    const Address = this.signupForm.get('Address').value;
    const City = this.signupForm.get('City').value;
    const Zip = this.signupForm.get('Zip').value;
    const Country = this.signupForm.get('Country').value;
    const Phone = this.signupForm.get('Phone').value;
    const FullName = this.signupForm.get('FirstName').value + ' ' + this.signupForm.get('LastName').value;

    this.authService.createNewUser(email, password, Address, City, Zip, Country, Phone, FullName)
      .then((e) => {
      },
        (error) => {
          this.errorMessage = error;
        });

  }
}
