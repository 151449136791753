<!-- Breadcrumb Begin -->
<div class="breadcrumb-option">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="breadcrumb__links">
                    <a href="../home"><i class="fa fa-home"></i> Home</a>
                    <span>Login</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Breadcrumb End -->
<!-- Checkout Section Begin -->
<section class="checkout spad">
    <div class="container" style="height: 450px;">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="checkout__form">
            <div class="row">
                <div class="col-lg-12">
                    <h5>Login information</h5>
                    <p class="text-danger">{{ errorMessage }}</p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="checkout__form__input">
                                <p>Email <span>*</span></p>
                                <input type="email" name="email" formControlName="email" required>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="checkout__form__input">
                                <p>Password <span>*</span></p>
                                <input type="password" name="password" formControlName="password" required>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <button type="submit" class="site-btn" [disabled]="loginForm.invalid">Login Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
<!-- Checkout Section End -->
<!-- Instagram Begin -->
<hr>
