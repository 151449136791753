import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../http.service';
import { Cards } from '../cards';
import * as $ from 'jquery';
import 'owl.carousel';
import { AppComponent } from './../app.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  Cards: Cards[];
  Card: {};
  money: any;


  constructor(private httpService: HttpService, private router: Router, private app: AppComponent) {
    this.httpService.GetCards().subscribe(data => {
      this.Cards = data;
    });
  }


  // tslint:disable-next-line:typedef
  addFavorite(id) {
    const Profile = JSON.parse(sessionStorage.getItem('Profile'));
    const Favorite = JSON.parse(sessionStorage.getItem('wishlist'));

    const check = Favorite.some((el) => {
      return el.id === id;
    });

    if (Profile.length > 0) {
      this.Card = this.Cards.filter((hero) => {
        return hero.id === id;
      });
      if (check === false) {

        // tslint:disable-next-line:variable-name
        const Fav = [{
          // tslint:disable-next-line:object-literal-shorthand
          id: id
        }];

        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Favorite it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.httpService.Favorite(Fav).subscribe(data => {
              if ((data as any).Status === 'Success') {
                Favorite.push(this.Card[0]);
                sessionStorage.setItem('wishlist', JSON.stringify(Favorite));
                this.app.SetData();
              } else {
                Swal.fire(
                  'Warning!',
                  'Error to Favorite Card!',
                  'warning'
                );
              }
            });
            Swal.fire(
              'Favorite!',
              'This Card has been add in Favorite List.',
              'success'
            );
          }
        });



      } else {
        Swal.fire(
          'Warning!',
          'Exist In Favorite List!',
          'warning'
        );
      }

    } else {
      this.router.navigate(['./Login']);
    }


  }

  // tslint:disable-next-line:typedef
  Buy(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, i Confirm it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.Card = this.Cards.filter((hero) => {
          return hero.id === id;
        });
        sessionStorage.setItem('Buy', JSON.stringify(this.Card));
        this.router.navigate(['./Checkout']);
      }
    });


  }

  ngOnInit(): void {
    this.money = sessionStorage.getItem('money');

    $('.loader').fadeOut();
    $('#preloder').delay(200).fadeOut('slow');

    /*------------------
        Product filter
    --------------------*/
    // tslint:disable-next-line:typedef
    // $('.filter__controls li').on('click', () => {
    //   $('.filter__controls li').removeClass('active');
    //   $(this).addClass('active');
    // });
    // if ($('.property__gallery').length > 0) {
    //   // tslint:disable-next-line:prefer-const
    //   let containerEl = document.querySelector('.property__gallery');
    // }
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
    $('#home').addClass('active');
    $('#Gift_Cards').removeClass('active');
    $('#Gaming_Cards').removeClass('active');
    $('#Charge_Cards').removeClass('active');
    $('#Contact').removeClass('active');

    // $('.image-popup').magnificPopup({
    //   type: 'image'
    // });
  }

}
