<!-- Breadcrumb Begin -->
<div class="breadcrumb-option">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="breadcrumb__links">
                    <a href="../Home"><i class="fa fa-home"></i> Home</a>
                    <span>WishList</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Breadcrumb End -->
<!-- Shop Cart Section Begin -->
<section class="shop-cart spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="shop__cart__table">
                    <table>
                        <thead>
                            <tr>
                                <th>WishList</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let fav of Favorite">
                                <td class="cart__product__item">
                                    <img src="https://api.e-store-dz.com{{fav.picture}}" alt="" data-pagespeed-url-hash="4207875790" width="80">
                                    <div class="cart__product__item__title">
                                        <h6>{{fav.name}} </h6>
                                        <div class="rating">
                                            {{fav.price}} $
                                        </div>
                                    </div>
                                </td>
                                <td class="cart__price"> € {{fav.prix_euro_detail}}</td>
                                <td class="cart__total"> Da {{fav.prix_detail}}</td>
                                <td class="cart__close"><span class="icon_close" (click)="Delete(fav.id)"></span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="cart__btn update__btn">
                    <a href="./WishList"><span class="icon_loading"></span> Update WishList</a>
                </div>
            </div>
        </div>

    </div>
</section>
<!-- Shop Cart Section End -->
<!-- Instagram Begin -->
<div class="instagram">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                <div class="instagram__item" style="background-image: url('assets/img/instagram/insta-1.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
                    <div class="instagram__text">
                        <i class="fa fa-instagram"></i>
                        <a href="#">@E-Store</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                <div class="instagram__item" style="background-image: url('assets/img/instagram/insta-2.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
                    <div class="instagram__text">
                        <i class="fa fa-instagram"></i>
                        <a href="#">@E-Store</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                <div class="instagram__item" style="background-image: url('assets/img/instagram/insta-3.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
                    <div class="instagram__text">
                        <i class="fa fa-instagram"></i>
                        <a href="#">@E-Store</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                <div class="instagram__item" style="background-image: url('assets/img/instagram/insta-4.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
                    <div class="instagram__text">
                        <i class="fa fa-instagram"></i>
                        <a href="#">@E-Store</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                <div class="instagram__item" style="background-image: url('assets/img/instagram/insta-5.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
                    <div class="instagram__text">
                        <i class="fa fa-instagram"></i>
                        <a href="#">@E-Store</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                <div class="instagram__item " style="background-image: url('assets/img/instagram/insta-6.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
                    <div class="instagram__text">
                        <i class="fa fa-instagram"></i>
                        <a href="#">@E-Store</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
