import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AppComponent } from './../app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  errorMessage: string;
  AppComponent: any;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router , private app: AppComponent) { }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.initForm();
    $('#home').removeClass('active');
    $('#Gift_Cards').removeClass('active');
    $('#Gaming_Cards').removeClass('active');
    $('#Charge_Cards').removeClass('active');
    $('#Contact').removeClass('active');
  }

  // tslint:disable-next-line:typedef
  initForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]]
    });
  }

  // tslint:disable-next-line:typedef
  onSubmit() {
    const email = this.loginForm.get('email').value;
    const password = this.loginForm.get('password').value;

    this.authService.signInUser(email, password).then(
      async () => {
        await this.app.SetData();
        setTimeout(async () => {
          await this.app.SetData();
          this.router.navigate(['./Home']);
        }, 500);
      },
      (error) => {
        this.errorMessage = error;
      }
    );
  }



}
