import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { Coins } from '../cards';
import * as $ from 'jquery';
import { Options } from '@angular-slider/ngx-slider';
import { AppComponent } from './../app.component';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gaming-cards',
  templateUrl: './gaming-cards.component.html',
  styleUrls: ['./gaming-cards.component.css']
})
export class GamingCardsComponent implements OnInit {
  Coins: Coins[];
  Data: any;
  categories: any;
  Coin: any;
  money: any;
  value: any;
  options: Options;
  Coin_data: {};
  constructor(private httpService: HttpService, private app: AppComponent, private router: Router) {
    this.httpService.GetCoins().subscribe(data => {
      this.Coins = data;
      this.Data = data;

    });
  }


  // tslint:disable-next-line:typedef
  addFavorite(id) {
    const Profile = JSON.parse(sessionStorage.getItem('Profile'));
    const Favorite = JSON.parse(sessionStorage.getItem('wishlist'));

    const check = Favorite.some((el) => {
      return el.id === id;
    });

    if (Profile.length > 0) {
      this.Coin = this.Coins.filter((hero) => {
        return hero.id === id;
      });
      if (check === false) {

        // tslint:disable-next-line:variable-name
        const Fav = [{
          // tslint:disable-next-line:object-literal-shorthand
          id: id
        }];

        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Favorite it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.httpService.Favorite(Fav).subscribe(data => {
              if ((data as any).Status === 'Success') {
                Favorite.push(this.Coin[0]);
                sessionStorage.setItem('wishlist', JSON.stringify(Favorite));
                this.app.SetData();
              } else {
                Swal.fire(
                  'Warning!',
                  'Error to Favorite Card!',
                  'warning'
                );
              }
            });
            Swal.fire(
              'Favorite!',
              'This Card has been add in Favorite List.',
              'success'
            );
          }
        });
      } else {
        Swal.fire(
          'Warning!',
          'Exist In Favorite List!',
          'warning'
        );
      }

    } else {
      this.router.navigate(['./Login']);
    }


  }

  // tslint:disable-next-line:typedef
  Buy(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, i Confirm it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.Coin_data = this.Coins.filter((hero) => {
          return hero.id === id;
        });
        // console.log(this.Coin_data)
        sessionStorage.setItem('Buy', JSON.stringify(this.Coin_data));
        this.router.navigate(['./Checkout']);
      }
    });


  }

  // tslint:disable-next-line:typedef
  Categoriesdata() {
    this.categories = [];
    for (const result of this.Coins) {
      this.categories.push(result.name);
    }
    this.categories = this.categories.filter((elem, i, arr) => {
      if (arr.indexOf(elem) === i) {
        return elem;
      }
    });
    return this.categories;
  }


  // tslint:disable-next-line:typedef
  FilterPrice() {
    if (this.money === 'euro') {

      this.Data = this.Coins.filter((hero) => {
        return hero.prix_euro_detail >= 0 && hero.prix_euro_detail <= this.value;
      });
    } else {
      this.Data = this.Coins.filter((hero) => {
        return hero.prix_detail >= 0 && hero.prix_detail <= this.value;
      });
    }
  }

  // tslint:disable-next-line:typedef
  FilterData(test) {
    $('#product__item .product__item').fadeOut(1000, () => {
      this.Data = this.Coins.filter((hero) => {
        return hero.name === test;
      });
      $('#product__item .product__item').fadeIn(500);
    });

  }

  // tslint:disable-next-line:typedef
  AllDATA() {
    $('#product__item .product__item').fadeOut(1000, () => {
      this.Data = this.Coins;
      $('#product__item .product__item').fadeIn(500);
    });
  }


  ngOnInit(): void {
    this.money = sessionStorage.getItem('money');
    if (this.money === 'euro') {
      this.value = 500;
      this.options = {
        floor: 0,
        ceil: 500,
        getPointerColor: (value: number): string => {
          return '#dc3545';
        }
      };
    } else {
      this.value = 50000;
      this.options = {
        floor: 0,
        ceil: 50000,
        getPointerColor: (value: number): string => {
          return '#dc3545';
        }
      };
    }
  }


  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {

    $('#home').removeClass('active');
    $('#Gift_Cards').removeClass('active');
    $('#Gaming_Cards').addClass('active');
    $('#Charge_Cards').removeClass('active');
    $('#Contact').removeClass('active');
  }

}
