import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { Favorite } from '../cards';
import { AppComponent } from './../app.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-wish-list',
  templateUrl: './wish-list.component.html',
  styleUrls: ['./wish-list.component.css']
})
export class WishListComponent implements OnInit {
  Favorite: Favorite[];

  constructor(private httpService: HttpService, private app: AppComponent) {
    this.httpService.Get_Favorite().subscribe(data => {
      this.Favorite = data;
      sessionStorage.setItem('wishlist', JSON.stringify(data));
      this.app.SetData();
    });
  }

  // tslint:disable-next-line:typedef
  Delete(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.httpService.delete_Favorite(id).subscribe(data => {
          if ((data as any).Status === 'Success') {
            this.Favorite = this.Favorite.filter((el) => {
              return el.id !== id;
            });
            sessionStorage.setItem('wishlist', JSON.stringify(this.Favorite));
            this.app.SetData();
          } else {
            Swal.fire(
              'Warning!',
              'Error on Delete!',
              'warning'
            );
          }
        });
        Swal.fire(
          'Deleted!',
          'This Card has been deleted.',
          'success'
        );
      }
    });
  }

  ngOnInit(): void {
    $('#home').removeClass('active');
    $('#Gift_Cards').removeClass('active');
    $('#Gaming_Cards').removeClass('active');
    $('#Charge_Cards').removeClass('active');
    $('#Contact').removeClass('active');
  }

}
