<!-- Breadcrumb Begin -->
<div class="breadcrumb-option">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="breadcrumb__links">
                    <a href="../Home"><i class="fa fa-home"></i> Home</a>
                    <span>REGISTRATION</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Breadcrumb End -->
<!-- Checkout Section Begin -->
<section class="checkout spad">
    <div class="container">
        <form [formGroup]="signupForm" (ngSubmit)="onSubmit()" class="checkout__form">
            <div class="row">
                <div class="col-lg-12">
                    <h5>Registration detail</h5>
                    <p class="text-danger">{{ errorMessage }}</p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="checkout__form__input">
                                <p>First Name <span>*</span></p>
                                <input type="text" formControlName="FirstName" name="FirstName">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="checkout__form__input">
                                <p>Last Name <span>*</span></p>
                                <input type="text" formControlName="LastName" name="LastName">
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="checkout__form__input">
                                <p>Address <span>*</span></p>
                                <input type="text" formControlName="Address" placeholder="Street Address" name="Address">
                            </div>
                            <div class="checkout__form__input">
                                <p>Town/City <span>*</span></p>
                                <input type="text" formControlName="City" name="City">
                            </div>
                            <div class="checkout__form__input">
                                <p>Postcode/Zip <span>*</span></p>
                                <input type="text" formControlName="Zip" name="Zip">
                            </div>
                            <div class="checkout__form__input">
                                <p>Country <span>*</span></p>
                                <input type="text" formControlName="Country" name="Country">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="checkout__form__input">
                                <p>Phone <span>*</span></p>
                                <input type="text" formControlName="Phone" name="Phone">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="checkout__form__input">
                                <p>Email <span>*</span></p>
                                <input formControlName="email" name="email" type="email">
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="checkout__form__input">
                                <p>Account Password <span>*</span></p>
                                <input name="password" formControlName="password" type="password">
                            </div>
                            <div class="col-lg-12 mt-3">
                                <button type="submit" class="site-btn" [disabled]="signupForm.invalid">Registration Now</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
<!-- Checkout Section End -->
<!-- Instagram Begin -->
<hr>
