import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { HttpService } from '../http.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router, private httpService: HttpService) { }

  // tslint:disable-next-line:typedef
  createNewUser(email: string, password: string, Address: any, City: any, Zip: string, Country: any, Phone: string, FullName: string) {

    return new Promise<void>(
      async (resolve, reject) => {
        await firebase.auth().createUserWithEmailAndPassword(email, password).then(
          async () => {
            await firebase.auth().signInWithEmailAndPassword(email, password);
            await firebase.auth().onAuthStateChanged(
              async (user) => {
                if (user) {
                  const Registration = [{
                    // tslint:disable-next-line:object-literal-shorthand
                    email: email,
                    // tslint:disable-next-line:object-literal-shorthand
                    Address: Address,
                    // tslint:disable-next-line:object-literal-shorthand
                    City: City,
                    // tslint:disable-next-line:object-literal-shorthand
                    Zip: Zip,
                    // tslint:disable-next-line:object-literal-shorthand
                    Country: Country,
                    // tslint:disable-next-line:object-literal-shorthand
                    Phone: Phone,
                    // tslint:disable-next-line:object-literal-shorthand
                    FullName: FullName,
                    // tslint:disable-next-line:object-literal-shorthand
                    User_id: user.uid
                  }];
                  await this.httpService.Registration(Registration).subscribe(data => {
                    if ((data as any).Status === 'Success') {
                      Swal.fire(
                        'Good job!',
                        'Successfully Registered!',
                        'success'
                      );
                      resolve();
                      this.router.navigate(['./Login']);
                    } else {
                      Swal.fire(
                        'Warning!',
                        'Error In Registration!',
                        'warning'
                      );
                      reject();
                    }
                  }, err => {
                    reject(err);
                    console.log(err);
                  });
                } else {
                  reject();
                }
              }
            );
            this.router.navigate(['./Login']);
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  // tslint:disable-next-line:typedef
  signInUser(email: string, password: string) {
    return new Promise<void>(
      async (resolve, reject) => {
        await firebase.auth().signInWithEmailAndPassword(email, password).then(
          async () => {
            await firebase.auth().onAuthStateChanged(
              async (user) => {
                if (user) {
                  const token = (user as any).xa;
                  sessionStorage.setItem('access', token);

                  await this.httpService.Login(token).subscribe(async data => {
                    if ((data as any).id) {
                      await this.httpService.Get_Favorite().subscribe(fav => {
                        sessionStorage.setItem('wishlist', JSON.stringify(fav));
                        sessionStorage.setItem('Profile', '[' + JSON.stringify(data) + ']');
                      });
                      Swal.fire(
                        'Good job!',
                        'Successfully Login!',
                        'success'
                      );
                      resolve();
                    } else {
                      Swal.fire(
                        'Warning!',
                        'Error In Login!',
                        'warning'
                      );
                      reject();
                    }
                  });

                } else {
                  reject();
                  this.router.navigate(['./Login']);
                }
              }
            );
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  // tslint:disable-next-line:typedef
  signOutUser() {
    firebase.auth().onAuthStateChanged(
      async (user) => {
        if (user) {
          sessionStorage.setItem('Profile', '[]');
          sessionStorage.setItem('wishlist', '[]');
          sessionStorage.setItem('Buy', '[]');
          sessionStorage.setItem('access', '');

          await firebase.auth().signOut().then(() => {
            setTimeout(() => {
              this.router.navigate(['./Login']);
            }, 3000);
          }).catch((error) => {
            this.router.navigate(['./Login']);
          });
        }else{
          sessionStorage.setItem('Profile', '[]');
          sessionStorage.setItem('wishlist', '[]');
          sessionStorage.setItem('access', '');
          this.router.navigate(['./Login']);
        }
      }
    );

  }
}
