import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cards } from './cards';
import { Coins } from './cards';
import { Profile } from './cards';
import { Favorite } from './cards';


@Injectable({
  providedIn: 'root'
})

export class HttpService {

  constructor(private http: HttpClient) { }

  GetCards(): Observable<Cards[]> {
    return this.http.get<Cards[]>('https://eleulmasat.com.dz/Activation/get_All');
  }

  GetCoins(): Observable<Coins[]> {
    return this.http.get<Coins[]>('https://api.ultracamd.info/api/GetCoins');
  }

  GetCharge(): Observable<Coins[]> {
    return this.http.get<Coins[]>('https://api.ultracamd.info/api/getCharge');
  }

  // tslint:disable-next-line:typedef
  Registration(Registration: { 'email': any; 'Address': any; 'City': any; 'Zip': any; 'Country': any; 'Phone': any; 'FullName': any; 'User_id': any; }[]) {
    return this.http.post('https://api.ultracamd.info/api/Registration', Registration, {
      headers: new HttpHeaders(
        {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Content-Type': 'application/json',
          // tslint:disable-next-line:object-literal-key-quotes
          'Accept': '*/*',
        }),
    });
  }


  // tslint:disable-next-line:typedef
  Favorite(Fav: { 'id': number; }[]) {
    return this.http.post('https://api.ultracamd.info/api/Favorite', Fav, {
      headers: new HttpHeaders(
        {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'post',
          'Content-Type': 'application/json',
          // tslint:disable-next-line:object-literal-key-quotes
          'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
          // tslint:disable-next-line:object-literal-key-quotes
          'Accept': '*/*',
        }),
    });
  }

  Login(token): Observable<Profile[]> {
    return this.http.get<Profile[]>('https://api.ultracamd.info/api/Profile', {
      headers: new HttpHeaders(
        {
          'Access-Control-Allow-Origin': '*',
          // tslint:disable-next-line:object-literal-key-quotes
          'Authorization': 'Bearer ' + token,
          // tslint:disable-next-line:object-literal-key-quotes
          'Accept': '*/*',
        }),
    });
  }


  Get_Favorite(): Observable<Favorite[]> {
    return this.http.get<Favorite[]>('https://api.ultracamd.info/api/Favorite', {
      headers: new HttpHeaders(
        {
          'Access-Control-Allow-Origin': '*',
          // tslint:disable-next-line:object-literal-key-quotes
          'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
          // tslint:disable-next-line:object-literal-key-quotes
          'Accept': '*/*',
        }),
    });
  }

  // tslint:disable-next-line:typedef
  delete_Favorite(id) {
    return this.http.delete('https://api.ultracamd.info/api/Favorite/' + id, {
      headers: new HttpHeaders(
        {
          'Access-Control-Allow-Origin': '*',
          // tslint:disable-next-line:object-literal-key-quotes
          'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
          // tslint:disable-next-line:object-literal-key-quotes
          'Accept': '*/*',
        }),
    });
  }


  // tslint:disable-next-line:typedef
  subscribe(Subscribe: { 'email': any; }[]) {
    return this.http.post('https://api.ultracamd.info/api/subscribe', Subscribe);
  }

}
