import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';


import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CONTACTComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { WishListComponent } from './wish-list/wish-list.component';
import { CartComponent } from './cart/cart.component';
import { AuthGuardService } from './services/auth-guard.service';
import { GiftCardComponent } from './gift-card/gift-card.component';
import { ChargeCardsComponent } from './charge-cards/charge-cards.component';
import { GamingCardsComponent } from './gaming-cards/gaming-cards.component';
import { CheckoutComponent } from './checkout/checkout.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'Home', component: HomeComponent },
  { path: 'Gift_Cards', component: GiftCardComponent },
  { path: 'Contact', component: CONTACTComponent },
  { path: 'Login', component: LoginComponent },
  { path: 'Register', component: SignupComponent },
  { path: 'Charge_Cards', component: ChargeCardsComponent },
  { path: 'Shopping', component: GamingCardsComponent },
  { path: 'WishList', canActivate: [AuthGuardService], component: WishListComponent },
  { path: 'Checkout', canActivate: [AuthGuardService], component: CheckoutComponent },
  { path: 'Cart', canActivate: [AuthGuardService], component: CartComponent },
  { path: '*', component: HomeComponent },
  { path: '**', component: HomeComponent },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]

})
export class AppRoutingModule { }
