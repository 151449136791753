<!-- Breadcrumb Begin -->
<div class="breadcrumb-option">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="breadcrumb__links">
          <a href="./index.html"><i class="fa fa-home"></i> Home</a>
          <span>Shopping cart</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Breadcrumb End -->
<!-- Shop Cart Section Begin -->
<section class="shop-cart spad">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="shop__cart__table">
          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="cart__product__item">
                  <img src="img/shop-cart/cp-1.jpg" alt="" data-pagespeed-url-hash="4207875790"
                    onload="pagespeed.CriticalImages.checkImageForCriticality(this);">
                  <div class="cart__product__item__title">
                    <h6>Chain bucket bag</h6>
                    <div class="rating">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </div>
                  </div>
                </td>
                <td class="cart__price">$ 150.0</td>
                <td class="cart__quantity">
                  <div class="pro-qty">
                    <input type="text" value="1">
                  </div>
                </td>
                <td class="cart__total">$ 300.0</td>
                <td class="cart__close"><span class="icon_close"></span></td>
              </tr>
              <tr>
                <td class="cart__product__item">
                  <img src="img/shop-cart/cp-2.jpg" alt="" data-pagespeed-url-hash="207408415"
                    onload="pagespeed.CriticalImages.checkImageForCriticality(this);">
                  <div class="cart__product__item__title">
                    <h6>Zip-pockets pebbled tote briefcase</h6>
                    <div class="rating">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </div>
                  </div>
                </td>
                <td class="cart__price">$ 170.0</td>
                <td class="cart__quantity">
                  <div class="pro-qty">
                    <input type="text" value="1">
                  </div>
                </td>
                <td class="cart__total">$ 170.0</td>
                <td class="cart__close"><span class="icon_close"></span></td>
              </tr>
              <tr>
                <td class="cart__product__item">
                  <img src="img/shop-cart/cp-3.jpg" alt="" data-pagespeed-url-hash="501908336"
                    onload="pagespeed.CriticalImages.checkImageForCriticality(this);">
                  <div class="cart__product__item__title">
                    <h6>Black jean</h6>
                    <div class="rating">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </div>
                  </div>
                </td>
                <td class="cart__price">$ 85.0</td>
                <td class="cart__quantity">
                  <div class="pro-qty">
                    <input type="text" value="1">
                  </div>
                </td>
                <td class="cart__total">$ 170.0</td>
                <td class="cart__close"><span class="icon_close"></span></td>
              </tr>
              <tr>
                <td class="cart__product__item">
                  <img src="img/shop-cart/cp-4.jpg" alt="" data-pagespeed-url-hash="796408257"
                    onload="pagespeed.CriticalImages.checkImageForCriticality(this);">
                  <div class="cart__product__item__title">
                    <h6>Cotton Shirt</h6>
                    <div class="rating">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </div>
                  </div>
                </td>
                <td class="cart__price">$ 55.0</td>
                <td class="cart__quantity">
                  <div class="pro-qty">
                    <input type="text" value="1">
                  </div>
                </td>
                <td class="cart__total">$ 110.0</td>
                <td class="cart__close"><span class="icon_close"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="cart__btn">
          <a href="#">Continue Shopping</a>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="cart__btn update__btn">
          <a href="#"><span class="icon_loading"></span> Update cart</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 offset-lg-8">
        <div class="cart__total__procced">
          <h6>Cart total</h6>
          <ul>
            <li>Subtotal <span>$ 750.0</span></li>
            <li>Total <span>$ 750.0</span></li>
          </ul>
          <a href="#" class="primary-btn">Proceed to checkout</a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Shop Cart Section End -->
<!-- Instagram Begin -->
<div class="instagram">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-2 col-md-4 col-sm-4 p-0">
        <div class="instagram__item" style="background-image: url('assets/img/instagram/insta-1.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
          <div class="instagram__text">
            <i class="fa fa-instagram"></i>
            <a href="#">@E-Store</a>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4 p-0">
        <div class="instagram__item" style="background-image: url('assets/img/instagram/insta-2.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
          <div class="instagram__text">
            <i class="fa fa-instagram"></i>
            <a href="#">@E-Store</a>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4 p-0">
        <div class="instagram__item" style="background-image: url('assets/img/instagram/insta-3.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
          <div class="instagram__text">
            <i class="fa fa-instagram"></i>
            <a href="#">@E-Store</a>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4 p-0">
        <div class="instagram__item" style="background-image: url('assets/img/instagram/insta-4.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
          <div class="instagram__text">
            <i class="fa fa-instagram"></i>
            <a href="#">@E-Store</a>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4 p-0">
        <div class="instagram__item" style="background-image: url('assets/img/instagram/insta-5.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
          <div class="instagram__text">
            <i class="fa fa-instagram"></i>
            <a href="#">@E-Store</a>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4 p-0">
        <div class="instagram__item " style="background-image: url('assets/img/instagram/insta-6.jpg'); background-repeat: no-repeat; background-position: center;  background-size:cover;">
          <div class="instagram__text">
            <i class="fa fa-instagram"></i>
            <a href="#">@E-Store</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
