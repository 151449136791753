<div id="preloder">
  <div class="loader"></div>
</div>
<!-- Offcanvas Menu Begin -->
<div class="offcanvas-menu-overlay"></div>
<div class="offcanvas-menu-wrapper">
  <div class="offcanvas__close">+</div>
  <ul class="offcanvas__widget">
    <li><span class="icon_search search-switch"></span></li>
    <li><a routerLink="./WishList"><span class="icon_heart_alt"></span>
        <div class="tip" *ngIf="Favorite.length !== 0"> {{Favorite.length}}</div>
      </a></li>
    <li><a routerLink="./Cart"><span class="icon_bag_alt"></span>
        <div class="tip">2</div>
      </a></li>
  </ul>
  <div class="offcanvas__logo">
    <a routerLink="./Home">
      <img src="assets/img/logo.png" alt="" width="150"></a>
  </div>
  <div id="mobile-menu-wrap"></div>
  <div class="offcanvas__auth">
    <a href="#" (ngclick)="logout()">Logout</a>
    <a href="./Login">Login</a>
    <a href="./Register">Register</a>
  </div>
</div>
<!-- Offcanvas Menu End -->
<!-- Header Section Begin -->
<header class="header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-3 col-lg-2">
        <div class="header__logo">
          <a href="./Home"><img src="assets/img/logo.png" alt="" width="150"></a>
        </div>
      </div>
      <div class="col-xl-6 col-lg-7">
        <nav class="header__menu">
          <ul>
            <li id="home"><a href="./Home">Home</a></li>
           <!--<li id="Gift_Cards"><a href="./Gift_Cards">Gift Cards</a></li>-->
            <li id="Gaming_Cards"><a href="./Shopping">Shopping</a></li>
            <!--<li id="Charge_Cards"><a href="./Charge_Cards">Charge Cards</a></li>-->
            <li id="Contact"><a href="./Contact">Contact</a></li>
            <li><a target="_blank" href="https://eleulmasat.com.dz/Activation/">Activation Section</a></li>
          </ul>
        </nav>
      </div>
      <div class="col-lg-3">
        <div class="header__right">
          <div class="header__right__auth">
            <a (click)="logout()" style="cursor: pointer;" *ngIf="Profile.length > 0"> Logout</a>
            <a href="./Login" *ngIf="Profile.length === 0"> Login</a>
            <a href="./Register" *ngIf="Profile.length == 0"> Register</a>
            <a href="./Profile" *ngIf="Profile.length > 0"> {{Profile[0].Fullname}}</a>
          </div>
          <ul class="header__right__widget">
            <li><span class="icon_search search-switch"></span></li>
            <li><a href="./WishList"><span class="icon_heart_alt"></span>
                <div class="tip" *ngIf="Favorite.length !== 0"> {{Favorite.length}}</div>
              </a></li>
           <!-- <li>
              <select style="font-size: 14px; border: 0ch;">
                <option value="En" selected>En</option>
                <option value="AR">AR</option>
              </select>
            </li>-->
            <li>
              <select id="money" (change)="GetMoney()" style="font-size: 14px; border: 0ch;">
                <option value="da">Da</option>
                <option value="euro">€</option>
              </select>
            </li>
            <!--<li><a href="./Cart"><span class="icon_bag_alt"></span></a></li>
              <li><a href="./Cart"><span class="icon_bag_alt"></span></a></li>-->
          </ul>
        </div>
      </div>
    </div>
    <div class="canvas__open">
      <i class="fa fa-bars"></i>
    </div>
  </div>
</header>
<router-outlet></router-outlet>

<!-- Footer Section Begin -->
<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-7">
        <div class="footer__about">
          <div class="footer__logo">
            <a href="./Home"><img src="assets/img/logo.png" width="150"></a>
          </div>
          <p>Buy a gift card at a store near you and give the latest entertainment for Android devices and more.
          </p>
          <div class="footer__payment">
            <a href="./Home"><img src="assets/img/payment/xpayment-1.png.pagespeed.ic.n2kKvTDVR3.webp" alt=""></a>
            <a href="./Home"><img src="assets/img/payment/xpayment-2.png.pagespeed.ic.ZRduM6EAng.webp" alt=""></a>
            <a href="./Home"><img src="assets/img/payment/124.png" alt=""></a>
            <a href="./Home"><img src="assets/img/payment/xpayment-4.png.pagespeed.ic.KD_lCYLjIB.webp" alt=""></a>
            <a href="./Home"><img src="assets/img/payment/xpayment-5.png.pagespeed.ic.KHHEnRgd3x.png" alt=""></a>
            <a href="./Home"><img src="assets/img/payment/123.png" alt=""></a>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-5">
        <div class="footer__widget">
          <h6>Quick links</h6>
          <ul>
            <li><a href="./Contact">Contact</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-4">
        <div class="footer__widget">
          <h6>Account</h6>
          <ul>
            <li><a href="#">My Account</a></li>
            <li><a href="./Orders">Orders</a></li>
            <li><a href="./WishList">Wishlist</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-8 col-sm-8">
        <div class="footer__newslatter">
          <h6>NEWSLETTER</h6>
          <form [formGroup]="SubscribeForm" (ngSubmit)="onSubmit()">
            <input type="text" formControlName="Email" placeholder="Email">
            <button type="submit" class="site-btn" [disabled]="SubscribeForm.invalid">Subscribe</button>
          </form>
          <div class="footer__social">
            <a href="https://www.facebook.com/djostoredz/"><i class="fa fa-facebook"></i></a>
            <a href="https://www.instagram.com/estoredz5/"><i class="fa fa-instagram"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
        <div class="footer__copyright__text">
          <p>Copyright &copy; 2022 - All rights reserved </p>
        </div>
        <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
      </div>
    </div>
  </div>
</footer>
<!-- Footer Section End -->
<!-- Search Begin -->
<div class="search-model">
  <div class="h-100 d-flex align-items-center justify-content-center">
    <div class="search-close-switch">+</div>
    <form class="search-model-form">
      <input type="text" id="search-input" placeholder="Search here.....">
    </form>
  </div>
</div>
